const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "chapo_length_number": 559,
  "date_format": {
    "fr": "d MMMM yyyy"
  },
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "search_token": "ea85c2f70a92bb2c18b6689384c0d2",
  "v3_share_label": {
    "fr": "Je partage"
  },
  "hour_format": {
    "fr": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639198",
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form/instit/",
  "site_name": "Keolis-Rennes.com",
  "v3_social_networks": [
    "GT8_VDUuQ5aFZk5H2tNa7w",
    "bWaeQd9gQh6BkaoFJGHUWQ",
    "XDtupnoaR7uBVVhjzTXBPA",
    "XNHH3qlbSv2IbCyfOXUolw"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "google_tag_manager_id": "GTM-TZ3LNQHG",
  "meta_description": {
    "fr": "Keolis Rennes en action. À la une. Keolis Rennes recrute pour STAR : conducteurs/conductrices en parcours de formation..."
  },
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "google_analytics_key": "",
  "subsidiary_logo": {
    "fr": {
      "path": "/97186/1680255937-logo-keolis-rennes.png",
      "format": "png",
      "size": 50294,
      "alt": "Keolis Rennes",
      "title": "Keolis Rennes",
      "width": 3446,
      "height": 1060
    }
  },
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "fr": {
      "path": "/97186/1724158890-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "home_page_title": {
    "fr": "Accueil"
  },
  "mailto_addresse": "mailto:someone@example.com",
  "share_buttons_title": {
    "fr": "Partager"
  },
  "transport_mode_title": {
    "fr": "Modes de transport"
  },
  "facebook_label": {
    "fr": "Facebook"
  },
  "token_google_site_verification": "",
  "twitter_label": {
    "fr": "Twitter"
  },
  "linkedin_label": {
    "fr": "LinkedIn"
  },
  "mailto_label": {
    "fr": "Partager par mail"
  },
  "download_label": {
    "fr": "Télécharger"
  },
  "updated_at": "2024-12-04T18:14:15.805+01:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.keolis-rennes.com/"
})